import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// 配置 ElementUI
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

// 配置 全局初始化样式
import '@/assets/css/reset.scss';

//跳转
import '@/utils/mixin.js'

// 自定义按钮
import '@/utils/permission.js'

// 自定义按钮
import '@/utils/permission.js'

//引入echarts图表
import echarts from 'echarts'
Vue.prototype.$echarts = echarts;

Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
