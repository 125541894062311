import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
// import Home from '../views/Home.vue'

Vue.use(VueRouter)

const comeRule = { path: 'welcome', alias: '/', component: () => import('../views/index/Welcome.vue') } // 首页
const authRule = { path: 'auth', component: () => import('../views/author/Auth.vue') } // 权限
const roleRule = { path: 'role', component: () => import('../views/author/Role.vue') } // 角色管理
const groupRule = { path: 'grouprole', component: () => import('../views/author/GroupRole.vue') } // 权限组
const userRule = { path: 'user', component: () => import('../views/user/User.vue') } // 用户管理
const payRule = { path: 'pay', component: () => import('../views/finance/Pay.vue') } // 费用管理
const costRule = { path: 'cost', component: () => import('../views/finance/Cost.vue') } // 缴费统计
const orderRule = { path: 'order', component: () => import('../views/finance/Order.vue') } // 订单管理
const setupRule = { path: 'setup', component: () => import('../views/activity/Setup.vue') } //活动设置
const sendRule = { path: 'send', component: () => import('../views/activity/Sending.vue') } // 活动价格
const informRule = { path: 'inform', component: () => import('../views/activity/Information.vue') } // 活动信息
const branchRule = { path: 'branch', component: () => import('../views/campus/Branch.vue') } // 分校管理
const amherstRule = { path: 'amherst', component: () => import('../views/campus/Amherst.vue') } //校区人员
const classtypeRule = { path: 'classtype', component: () => import('../views/campus/ClassType.vue') } // 班级类型
const classrunRule = { path: 'classrun', component: () => import('../views/campus/ClassRun.vue') } //班级管理
const personRule = { path: 'person', component: () => import('../views/campus/Personnel.vue') } //班级人员
const manageRule = { path: 'manage', component: () => import('../views/manage/Manage.vue') } //信息管理
const admissRule = { path: 'admiss', component: () => import('../views/Toschool/Admission.vue') } //到校状态
const posterRule = { path: 'poster', component: () => import('../views/poster/Poster.vue') } // 广告管理
const advertRule = { path: 'advert', component: () => import('../views/poster/Advert.vue') } // 广告管理（管理员入口）
const positRule = { path: 'posit', component: () => import('../views/poster/posit.vue') } // 广告管理  新建位置

const ruleMapping = {
    '/welcome': comeRule,
    '/auth': authRule,
    '/role': roleRule,
    '/grouprole': groupRule,
    '/user': userRule,
    '/pay': payRule,
    '/cost': costRule,
    '/order': orderRule,
    '/setup': setupRule,
    '/send': sendRule,
    '/inform': informRule,
    '/branch': branchRule,
    '/amherst': amherstRule,
    '/classtype': classtypeRule,
    '/classrun': classrunRule,
    '/person': personRule,
    '/manage': manageRule,
    '/admiss': admissRule,
    '/poster': posterRule,
    '/advert': advertRule,
    '/posit': positRule,
}

const routes = [
    {//登录
        path: '/login',
        component: () => import('../views/login/Login.vue')
    },
    {//首页
        path: '/',
        component: () => import('../views/index/Index.vue'),
        children: [
            // {//首页数据展示
            //     path: '/welcome',
            //     alias: '/',
            //     component: () => import('../views/index/Welcome.vue')
            // },
            // {//广告管理  新增广告
            //     path: '/poster',
            //     component: () => import('../views/poster/Poster.vue')
            // },
            // {//广告管理  新增广告
            //     path: '/advert',
            //     component: () => import('../views/poster/Advert.vue')
            // },
            {//广告管理  新增广告
                path: '/built',
                component: () => import('../views/poster/Built.vue')
            },
        ]
    },
]

const router = new VueRouter({
    routes
})

//全局前置守卫
router.beforeEach((to, from, next) => {
    if (to.path == '/login') {
        next()
    } else {
        let token = sessionStorage.getItem('Token')
        if (!token) return next({ path: '/login' })
        next()
    }
})
export function initRouter () {
    // console.log(router)
    const currentRoutes = router.options.routes;
    const LeftList = store.state.LeftList;
    // console.log(LeftList)
    let arr = [];
    if (LeftList != null) {
        LeftList.forEach(item => {
            if (item.children.length != 0) {
                item.children.forEach(item => {
                    let temp = ruleMapping[item.auth_route]
                    currentRoutes[1].children.push(temp);
                    temp.meta = item.page_type.split(',');
                    arr.push(temp.meta)
                })
            }
        })
    }
    router.addRoutes(currentRoutes)
}


export default router
